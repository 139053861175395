<template>

          <div class="popup-login login" id="popup-login-account" v-if="displayLoginForm || displayRegisterForm">
            <div class="content">
                <div class="login-form" v-if='displayLoginForm'>
                    <h3 v-if="language=='fr'">Connexion</h3>
                    <h3 v-else>Log in</h3>
                    <template v-for="param in paramsLogin" :key="param.link">
                        <div class="input-line">
                            <input v-if="language=='fr'" :class="{error:(errors_login.email && param.link=='username') || (errors_login.password && param.link=='password')}" :type="param.type" :id="param.id" v-on:change="loginChange(param.link, $event)" :placeholder="param.placeholder">
                            <input v-else :class="{error:(errors_login.email && param.link=='username') || (errors_login.password && param.link=='password')}" :type="param.type" :id="param.id" v-on:change="loginChange(param.link, $event)" :placeholder="param.placeholder_en">
                            <div v-if="errors_login.email && param.link=='username'" class="error-message">
                                {{errors_login.email}}
                            </div>
                            <div v-if="errors_login.password && param.link=='password'" class="error-message">
                                {{errors_login.password}}
                            </div>
                        </div>
                    </template>
                    <a  v-if="language=='fr'" class="forgot" @click="displayForgotPassword()">Mot de passe oublié ?</a>
                    <a  v-else class="forgot" @click="displayForgotPassword()">Forgotten password?</a>
                    <center>
                        <button v-if="language=='fr'" id="login_button_account" @click="login_account()">Connexion</button>
                        <button v-else id="login_button_account" @click="login_account()">Log in</button>
                    </center>
                    <div>
                        <br />
                        <center>{{wrongEmail}}</center>
                        <center>{{errorLogin}}</center>
                        
                    </div>
                    <br />
                    <center v-if="language=='fr'">Vous n'avez pas de compte ? <a  class="register_link" @click='displayRegister()'>Inscrivez-vous</a>.<br /><br /></center>
                    <center v-else>You don't have account? <a  class="register_link" @click='displayRegister()'>Register</a>.<br /><br /></center>
                </div>
                <div class="register-form" v-if='displayRegisterForm'>
                    <h3  v-if="language=='fr'">Inscription</h3>
                    <h3  v-else>Register</h3>
                    
                    <template v-for="param in paramsRegister" :key="param.link">
                        <div class="input-line">
                            <input v-if="language=='fr'" :type="param.type" :id="param.id" v-on:change="registerChange(param.link, $event)" :placeholder="param.placeholder"
                                :class="{error:(errors_register.register_name && param.link=='register_name') || 
                                (errors_register.register_surname && param.link=='register_surname') || 
                                (errors_register.register_email && param.link=='register_email') || 
                                (errors_register.register_password && param.link=='register_password') || 
                                (errors_register.register_password_repeat && param.link=='register_password_repeat') ||
                                (errors_register.register_phone && param.link=='register_phone')}"
                            >
                            <input v-else :type="param.type" :id="param.id" v-on:change="registerChange(param.link, $event)" :placeholder="param.placeholder_en"
                                :class="{error:(errors_register.register_name && param.link=='register_name') || 
                                (errors_register.register_surname && param.link=='register_surname') || 
                                (errors_register.register_email && param.link=='register_email') || 
                                (errors_register.register_password && param.link=='register_password') || 
                                (errors_register.register_password_repeat && param.link=='register_password_repeat') ||
                                (errors_register.register_phone && param.link=='register_phone')}"
                            >
                             <div v-if="errors_register.register_name && param.link=='register_name'" class="error-message">
                                {{errors_register.register_name}}
                            </div>
                            <div v-if="errors_register.register_surname && param.link=='register_surname'" class="error-message">
                                {{errors_register.register_surname}}
                            </div>
                            <div v-if="errors_register.register_email && param.link=='register_email'" class="error-message">
                                {{errors_register.register_email}}
                            </div>
                            <div v-if="errors_register.register_password && param.link=='register_password'" class="error-message">
                                {{errors_register.register_password}}
                            </div>
                            <div v-if="errors_register.register_password_repeat && param.link=='password_repeat_register'" class="error-message">
                                {{errors_register.register_password_repeat}}
                            </div>
                            <div v-if="errors_register.register_phone && param.link=='register_phone'" class="error-message">
                                {{errors_register.register_phone}}
                            </div>
                           
                        </div>
                    </template>
                    <center>
                        <button  v-if="language=='fr'" id="register_button_account" @click="register_account()">Inscription</button>
                        <button  v-else id="register_button_account" @click="register_account()">Register</button>
                    </center>
                    <div>
                        <br />
                        <center>{{wrongEmail}}</center>
                        <center>{{errorRegister}}</center>
                        
                    </div>
                    <br />
                    <center v-if="language=='fr'">Vous avez déjà un compte ? <a  class="register_link" @click='displayLogin()'>Se connecter</a>.<br /><br /></center>
                    <center v-else>You already have an account? <a  class="register_link" @click='displayLogin()'>Log in</a>.<br /><br /></center>
                    
                </div>
            </div>
        </div>
</template>

<script>
import inputLogin from "../../data/popup/popupLogin";
import inputRegister from "../../data/popup/popupRegister";

const regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

export default {
    name: "PopupLoginComponent",
    props: ["language", "displayLoginForm", "displayRegisterForm", "errorLogin", "errorRegister","register_name","register_surname","register_email","register_password","register_password_repeat","register_phone"],
    methods: {
        loginChange(input,$event)
        {
            
            const {value} = $event.target
            this.errors_login={}
            let wrongEmailText
            if(this.language=='fr')
            {
                wrongEmailText=`Format de l'email incorrect`
            }
            else
            {
                wrongEmailText="Incorrect email format"
            }
            if(input == "username" && !value.match(regexEmail)){
                this.wrongEmail = wrongEmailText
                
                this.errors_login.email=wrongEmailText
                return
            }
            else {
                this.wrongEmail = ``
            }
            if(input == "username"){
                             
                this.email=value
                
            }
            else {
                this.password=value
                
            }
           
            
            this.$emit(`loginDataEv`, input, value)
        },
        registerChange(input,$event)
        {
            const {value} = $event.target
            if(input == "register_email" && !value.match(regexEmail)){
                this.wrongEmail = wrongEmailText
                return
            }
            this.$emit(`registerDataEv`, input, value)
        },
        displayRegister()
        {
            this.$emit('displayRegisterEv')
        },
        displayLogin()
        {
            this.$emit('displayLoginEv')
        },
        displayForgotPassword()
        {
            this.$emit('displayForgotPasswordEv')
        },
        login_account()
        {

            let errorMandatory
            if(this.language=='fr')
            {
                errorMandatory=`Ce champs est obligatoire.`
            }
            else
            {
                errorMandatory="This field is required."
            }
            
            this.errors_login={}
            if(this.email=="" || this.password=="") {
                
                if(this.email==""){
                    this.errors_login.email=errorMandatory
                }
                if(this.password==""){
                    this.errors_login.password=errorMandatory
                }
                
                //this.loading=false
            }  
            else {
                this.loading=true
                this.$forceUpdate();
                this.errors_login={}
                this.$emit('login_accountEv')
            }
            
        },
        register_account()
        {
            this.loading=true
            this.errors_register={}

            let errorMandatory

            let errorPassword
            if(this.language=='fr')
            {
                errorMandatory=`Ce champs est obligatoire.`
                errorPassword=`Les deux mots de passe ne sont pas identiques.`
            }
            else
            {
                errorMandatory="This field is required."
                errorPassword="Passwords are not identical."
            }
            
        
            if(this.register_name=="" || this.register_name==null)
            {
                this.errors_register.register_name=errorMandatory
            }
            if(this.register_surname=="" || this.register_surname==null)
            {
                this.errors_register.register_surname=errorMandatory
            }
            if(this.register_email=="" || this.register_email==null)
            {
                this.errors_register.register_email=errorMandatory
            }
            if(this.register_password=="" || this.register_password==null)
            {
                this.errors_register.register_password=errorMandatory
            }
            if(this.register_password_repeat=="" || this.register_password_repeat==null)
            {
                this.errors_register.register_password_repeat=errorMandatory
            }
            if(this.register_password_repeat!=this.register_password) {
                
                this.errors_register.register_password_repeat=errorPassword
                this.errors_register.register_password=errorPassword
            }
            
            
          
            if(Object.keys(this.errors_register).length==0)
            {
                
                this.$emit('register_accountEv')
            }
            else {
                this.loading=false
            }
            
        }
    },
    data(){
        const {paramsLogin} = inputLogin;
        const {paramsRegister} = inputRegister;
        return{
            paramsLogin,
            paramsRegister,
            wrongEmail:null,
            loading:true,
            email:"",
            password:"",
            errors_login:{},
            errors_register:{}
        }
    },
    mounted() {
        this.loading=false
    }
}
</script>

<style scoped>

.popup-login
        {
            animation: fadeanimation 0.4s ease;
        }
.error-message {
    position:absolute;
    text-align: right;
    bottom:-15px;
    right:0;
    color:red;
    font-size:11px;
}
.input-line {
    position:relative;
    margin-bottom:20px;
}
input.error {
    border:1px solid red !important;
}
</style>
<template>
     <content-loader  v-if="loginLoading" viewBox="0 0 520 230" primaryColor="#f3f3f3" secondaryColor="#cccccc">

        <rect x="110" y="20" rx="5" ry="5" width="300" height="10" />
        <rect x="110" y="60" rx="5" ry="5" width="300" height="10" />
        <rect x="110" y="100" rx="5" ry="5" width="300" height="10" />
        <rect x="110" y="140" rx="5" ry="5" width="300" height="10" />
        <rect x="110" y="180" rx="5" ry="5" width="300" height="10" />

    </content-loader>
    
    <div id="account-content" v-if="displayAccountContent">

        <PopupShareScan 
            :displayPopupShareScan="displayPopupShareScan"
            :errorShare="errorShare"
            @displayPopupShareScanToFalseEv="displayPopupShareScanToFalse"
        />
        <div class="account-top" v-if="language=='fr'">
            <div class="account-title">Bonjour <span id="username_account">{{capitalizeFirstLetter(account_name)}} {{capitalizeFirstLetter(account_surname)}}</span></div>
            <div class="account-subtitle">(vous n’êtes pas <span id="username_account2">{{capitalizeFirstLetter(account_name)}} {{capitalizeFirstLetter(account_surname)}}</span>  ? <a @click="() => logout()">Déconnexion</a>)</div>
            
            
            
        </div>
        <div class="account-top" v-else>
            <div class="account-title">Hello <span id="username_account">{{capitalizeFirstLetter(account_name)}} {{capitalizeFirstLetter(account_surname)}}</span></div>
            <div class="account-subtitle">(you are not <span id="username_account2">{{capitalizeFirstLetter(account_name)}} {{capitalizeFirstLetter(account_surname)}}</span>  ? <a @click="() => logout()">Disconnect</a>)</div>
            
            
            
        </div>
        <br />
        <br />
       
        <ul class="menu-account">
            <template v-for="item in menu" :key="item.text">
                <div class="element-account">
                    <li 
                        :class="{open:(item.link=='accountDetails' && displayAccountDetails) || (item.link=='addresses' && displayAddressContent) || (item.link=='prescriptions' && displayPrescriptionContent) || (item.link=='reviews' && displayReviewContent) ||  (item.link=='scans' && displayScanContent) || (item.link=='cardsDetails' && displayCards) || (item.link=='orders' && displayOrderList)}"

                        @click="selectFn(item.link)"><div class="img"><img :src="require('../../../../public/assets/images/' + item.img)" :width="width" /></div> 
                        <span v-if="language=='fr'">{{ item.text }}</span>
                        <span v-else>{{ item.text_en }}</span>
                    </li>
                    <AccountDetails

                        v-if="item.link=='accountDetails' && displayAccountDetails"

                        :language="language"
                        :account_name="account_name"
                        :account_surname="account_surname"
                        :account_email="account_email"
                        :displayAccountContent="displayAccountContent"

                        @editAccountEv="editAccount"
                    />

                    <div id="account-details-form" class="subcategory-account" v-if="displayEditAccount && item.link=='accountDetails'">
                        <div v-if="language=='fr'">
                            <h4>Modifier mes informations</h4>
                            <div class="login-form">
                                Nom :<br />
                                <input type="text" id="name_edit_account" v-model="account_name" placeholder="Nom"><br />
                                Prénom :<br />
                                <input type="text" id="surname_edit_account" v-model="account_surname" placeholder="Prénom"><br />
                                Email :<br />
                                <input type="email" id="email_edit_account" v-model="account_email" placeholder="Email"><br />
                                Téléphone :<br />
                                <input type="text" class="phone-input" v-model="account_phone" id="phone_edit_account"
                                    placeholder="Téléphone">
                                <br />
                                <button id="edit_account_button_account" @click="updateAccount()">Modifier mes informations</button>
                                <div id="error-message-account-update"></div>
                                <br />
                                <br />
                            </div>
                        </div>
                        <div v-else>
                            <h4>Edit my informations</h4>
                            <div class="login-form">
                                Name :<br />
                                <input type="text" id="name_edit_account" v-model="account_name" placeholder="Name"><br />
                                Surname :<br />
                                <input type="text" id="surname_edit_account" v-model="account_surname" placeholder="Surname"><br />
                                Email :<br />
                                <input type="email" id="email_edit_account" v-model="account_email" placeholder="Email"><br />
                                Phone :<br />
                                <input type="text" class="phone-input" v-model="account_phone" id="phone_edit_account"
                                    placeholder="Phone">
                                <br />
                                <button id="edit_account_button_account" @click="updateAccount()">Edit my informations</button>
                                <div id="error-message-account-update"></div>
                                <br />
                                <br />
                            </div>
                        </div>
                    </div>
                    
                    <AccountAdresses 
                        v-if="item.link=='addresses'"
                        :language="language"
                        :addresses="addresses"
                        :displayAddressContent="displayAddressContent"

                        @editAddressEv="editAddress"
                        @deleteAddressEv="deleteAddress"
                        @addAddressEv="addAddress"
                    />
                    <AccountPrescriptions
                    v-if="item.link=='prescriptions'"
                    :language="language"
                        :displayPrescriptionContent="displayPrescriptionContent"
                        :userPrescriptions="userPrescriptions"
                        :prescriptionSelected="prescriptionSelected"

                        @deletePrescriptionEv="deletePrescription"
                        @displayPrescriptionDetailsEv="displayPrescriptionDetails"
                    />
                    <AccountReviews 
                    v-if="item.link=='reviews'"
                    :language="language"
                        :displayReviewContent="displayReviewContent"
                        :userReviews="userReviews"
                        
                        @deleteReviewEv="deleteReview"
                    />
                                
                    <AccountScans
                    v-if="item.link=='scans'"
                    :language="language"
                        :displayScanContent="displayScanContent"
                        :userScans="userScans"
                        :loadingScans="loadingScans"

                        @setDefaultScanEv="setDefaultScan"
                        @shareScanEv="shareScan"
                        @displayPopupScanEv="displayPopupScan"
                        @editScanEv="editScan"
                        @deleteScanEv="deleteScan"
                    />

                    <AccountEditScan
                    v-if="item.link=='scans'"
                    :language="language"
                        :displayEditScanForm="displayEditScanForm"
                        :id_edit_scan="id_edit_scan"
                        :name_edit_scan="name_edit_scan"

                        @updateScanEv="updateScan"
                        @changeIDScanEv="changeIDScan"
                        @changeNameScanEv="changeNameScan"
                    />

                    <AccountCards 

                    v-if="item.link=='cardsDetails'"
:language="language"
                        :displayCards="displayCards"
                        :cards="cards"

                        @removeCardEv="removeCard"
                        @addCardFormEv="addCardForm"
                    />

                    <AccountAddCard 
                    v-if="item.link=='cardsDetails'"
                    :language="language"
                        :displayFormCard="displayFormCard" 
                        @addCardEv="addCard"
                    />

                    <AccountEditAddress
                    v-if="item.link=='addresses'"

                        :displayEditAddressForm="displayEditAddressForm"
                        :language="language"
                        :name_edit_address="name_edit_address"
                        :address1_edit_address="address1_edit_address"
                        :address2_edit_address="address2_edit_address"
                        :postcode_edit_address="postcode_edit_address"
                        :city_edit_address="city_edit_address"
                        :country_edit_address="country_edit_address"

                        @itemAddressChangeEv="itemAddressChange"
                        @updateAddressEv="updateAddress"
                    />

                    <AccountCreateAddress
                    v-if="item.link=='addresses'"
                    :language="language"
                        :displayAddAddressForm="displayAddAddressForm"
                        @createAddressEv="createAddress"    
                        @itemAddressCreateChangeEv="itemAddressCreateChange"   
                    />

                    <div id="order_list" class="subcategory-account" v-if="displayOrderList && item.link=='orders'">
                        <h4 v-if="language=='fr'">Vos commandes</h4>
                        <h4 v-else>Your orders</h4>
                        <div v-if="orders.length== 0">
                            <span  v-if="language=='fr'">
                                Vous n'avez aucune commande pour le moment.<br /><br />
                            </span>
                            <span  v-else>
                                You don't have any order for the moment.<br /><br />
                            </span>
                        </div>
                        <table v-else class='table'>
                            <template v-for="order in orders" :key="order.id">
                                <tr v-if="order.status!=null">
                                    <td v-if="order.payment !== null">n°{{ order.id }}</td>
                                    <td v-if="order.payment !== null">{{ order.date }}</td>
                                    <td v-if="order.payment !== null">Total : {{ order.amount }}€</td>
                                    <td v-if="order.payment !== null">
                                        <a :href="'/detail-commande/' + order.id" v-if="language=='fr'" target="_blank">Détail commande</a>
                                        <a :href="'/detail-commande/' + order.id" v-else target="_blank">Order detail</a>
                                    </td>
                                </tr>
                            </template>
                        </table>
                    </div>
                    

                </div>
            </template>

        </ul>
       

    </div>
   
</template>

<script>
import capitalizeFirstLetter from "../../../utils/format/capitalizeFirstLetter"
import { ContentLoader } from 'vue-content-loader';
// import children
import PopupShareScan from "../../popup/PopupShareScan.vue"
import AccountMenu from "./AccountGrandChildren/AccountMenuComponent.vue"
import AccountDetails from './AccountGrandChildren/SubCategories/AccountDetailsComponent.vue'
import AccountAdresses from "./AccountGrandChildren/SubCategories/AccountAddressesComponent.vue"
import AccountPrescriptions from "./AccountGrandChildren/SubCategories/AccountPrescriptionsComponent.vue"
import AccountReviews from "./AccountGrandChildren/SubCategories/AccountReviewsComponent.vue"
import AccountScans from "./AccountGrandChildren/SubCategories/AccountScansComponent.vue"
import AccountEditScan from "./AccountGrandChildren/SubCategories/AccountEditScanComponent.vue"
import AccountCards from "./AccountGrandChildren/SubCategories/AccountCardsComponent.vue"
import AccountAddCard from "./AccountGrandChildren/SubCategories/AccountAddCardComponent.vue"
import AccountEditAddress from './AccountGrandChildren/SubCategories/AccountEditAddressComponent.vue'
import AccountCreateAddress from "./AccountGrandChildren/SubCategories/AccountCreateAddress.vue"
import menuData from "../../../data/account/menu_data"
import { getCurrentInstance } from 'vue';


export default {
    name: "AccountContent",
    props: [
        "language",
            "displayAccountContent",
            "displayPopupShareScan",
            "errorShare",
            "account_name",
            "account_surname",
            "displayAccountDetails",
            "displayOrderList",
            "account_email",
            "displayAddressContent",
            "addresses",
            "displayPrescriptionContent",
            "userPrescriptions",
            "prescriptionSelected",
            "displayReviewContent",
            "userReviews",
            "displayScanContent",
            "userScans",
            "displayEditScanForm",
            "displayCards",
            "cards",
            "displayFormCard",
            "displayEditAddressForm",
            "displayAddAddressForm",
            "displayEditAccount",
            "orders",
            "name_edit_address",
            "address1_edit_address",
            "address2_edit_address",
            "postcode_edit_address",
            "city_edit_address",
            "country_edit_address",
            "loadingScans",
            "id_edit_scan",
            "name_edit_scan"
    ],
    components: {
        ContentLoader,
        PopupShareScan,
        AccountMenu,
        AccountDetails,
        AccountAdresses,
        AccountPrescriptions,
        AccountReviews,
        AccountScans,
        AccountEditScan,
        AccountCards,
        AccountAddCard,
        AccountEditAddress,
        AccountCreateAddress,
    },
    
    data() {
        const {menu} = menuData;
        return {
            menu,
            loginLoading:false,
            capitalizeFirstLetter
        }
    },
    mounted() {
        //this.loginLoading=false
    },
    methods: {
        forceRerender () {
            const instance = getCurrentInstance();
            instance.proxy.forceUpdate();
        },
        deleteScan(id){
            this.$emit('deleteScanEv', id)
        },
        shareScan(id)
        {
            this.$emit('shareScanEv', id)
        },
        displayPopupScan(id)
        {
            this.$emit('displayPopupScanEv', id)
        },
        setDefaultScan(index) {
            this.$emit('setDefaultScanEv', index)
        },
        displayPopupShareScanToFalse()
        {
            this.$emit('displayPopupShareScanToFalseEv');
        },
        selectFn(link)
        {
            this.$emit('selectEv', link)
        
        },
        editAccount()
        {
            this.$emit('editAccountEv')
        },
        editAddress(id,index)
        {
            this.$emit('editAddressEv',id,index)
        },
        deleteAddress(id)
        {
            this.$emit('deleteAddressEv',id)
        },
        addAddress()
        {
            this.$emit('addAddressEv')
        },
        deletePrescription(id){
            this.$emit('deletePrescriptionEv', id)
        },
        displayPrescriptionDetails(index){
            this.$emit('displayPrescriptionDetailsEv', index)
        },
        editScan(id,index){
            this.$emit('editScanEv', id,index)
        },
        deleteReview(id){
            this.$emit('deleteReviewEv', id)
        },
        updateScan(){
            this.$emit('updateScanEv')
        },
        changeIDScan(e){
            this.$emit('changeIDScanEv', e)
        },
        changeNameScan(e){
            this.$emit('changeNameScanEv', e)
        },
        removeCard(id)
        {
            this.$emit('removeCardEv', id)
        },
        addCardForm()
        {
            this.$emit('addCardFormEv')
        },
        addCard()
        {
            this.$emit('addCardEv')
        },
        itemAddressChange(link,value)
        {
            this.$emit('itemAddressChangeEv', link, value)
        },
        createAddress()
        {
            this.$emit('createAddressEv')
        },
        itemAddressCreateChange(link,e)
        {
            this.$emit('itemAddressCreateChangeEv', link, e)
        },
        updateAddress()
        {
            this.$emit('updateAddressEv')
        },
        async forceRender() {
            // Remove MyComponent from the DOM
            this.loginLoading=true

            // Then, wait for the change to get flushed to the DOM
                await this.$nextTick();

                // Add MyComponent back in
                this.loginLoading=true
        },
        logout(){
            
            this.loginLoading=true
            this.$forceUpdate();

            
            this.$emit('logoutEv');
         
        }
    },
    
}
</script>

<style scoped>

.menu-account li {
    position: relative;
}

.menu-account li.open::after{
    content:"-";
   
   

}
.menu-account li::after{
    content:"+";
    position: absolute;
    right:20px;
    top:18px;
   

}
    .account-subtitle
    {
       
        font-size:28px;
    }
    .account-title
    {
        font-weight: 600;
        font-size:40px;
    }
    .account-top {
        width:70%;
        margin-left:auto;
        margin-right:auto;
        text-align: center;
    }

    
</style>
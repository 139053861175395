export default {
    paramsLogin: [
        {
            id: "login_account",
            type: "text",
            placeholder: "Adresse e-mail",
            placeholder_en: "E-mail address",
            link:"username",
        },
        {
            id: "password_account",
            type: "password",
            placeholder: "Mot de passe",
            placeholder_en:"Password",
            link:"password",
        },
    ]
}
export default {
    form : [
        {
            txt:"ID",
            link:"id",
            placeholder:"id",
            placeholder_en:"id",
            id: "id_edit_address"
        },
        {
            txt:"Nom",
            link:"name",
            placeholder:"Nom",
            placeholder_en:"Name",
            id: "name_edit_address"
        },
        {
            txt:"Adresse 1",
            link:"address1",
            placeholder:"Adresse 1",
            placeholder_en:"Address 1",
            id: "address1_edit_address"
        },
        {
            txt:"Adresse 2",
            link:"address2",
            placeholder:"Adresse 2",
            placeholder_en:"Address 2",
            id: "address2_edit_address"
        },
        {
            txt:"Code postal",
            link:"postcode",
            placeholder:"Code postal",
            placeholder_en:"Postcode",
            id: "postcode_edit_address"
        },
        {
            txt:"Ville",
            link:"city",
            placeholder:"Ville",
            placeholder_en:"City",
            id: "city_edit_address"
        },
        {
            txt:"Pays",
            link:"country",
            placeholder:"Pays",
            placeholder_en:"Country",
            id: "country_edit_address"
        },
    ]
}
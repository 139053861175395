<template>
  <div id="form-add-card" v-if='displayFormCard' class="subcategory-account">
    <form id="new_card_form" class="login-form">
        <div class="form-row">
            <label>Ajouter une carte :</label>
            <div id="card-element" class="form-control"></div>
        </div>
        <br />
        <button id="add_card" @click="addCard()" type="submit">Ajouter une carte</button>
        <br /><br />
    </form>

    <div id="card-errors" class="help-block" role="alert"></div>
</div>
</template>

<script>
export default {
    name: "AccountAddCard",
    props: ["displayFormCard"],
    methods : {
        addCard()
        {
            this.$emit('addCardEv')
        }
    }

}
</script>

<style>

</style>
<template>
    <div id="form-edit-address" class="subcategory-account" v-if="displayEditAddressForm">
        <h4 v-if="language=='fr'">Modifier une adresse</h4>  
    <h4  v-else>Edit address</h4>  
    <div class="login-form">

        <template v-for="item in form" :key="item.link">
               {{ item.link === "id" ? "" : item.txt }} :<br />
               <input v-if="language=='fr'" :type="item.link === 'id' ? 'hidden' : 'text'" :value="setValue(item.link)" :id="item.id" v-on:change="dataChange(item.link, $event)" :placeholder="item.placeholder">
               <input v-else :type="item.link === 'id' ? 'hidden' : 'text'" :value="setValue(item.link)" :id="item.id" v-on:change="dataChange(item.link, $event)" :placeholder="item.placeholder_en"><br />
        </template>       
        <input type="hidden" id="default_address_edit_address" value="false" placeholder="Nom">
        <button v-if="language=='fr'" id="edit_address_button" @click="updateAddress()" >Modifier mon adresse</button>
        <button v-else id="edit_address_button" @click="updateAddress()" >Save address</button>
        
        <div id="error-message-address-update"></div>
        <br /><br />
    </div>
</div>
</template>

<script>
import formAddress from '../../../../../data/account/form_address';

export default {
name: "AccountEditAddress",
props: [
        "displayEditAddressForm",
        "name_edit_address",
        "address1_edit_address",
        "address2_edit_address","language",
        "postcode_edit_address",
        "city_edit_address",
        "country_edit_address",
        
],
methods: {
    dataChange(link, e)
    {
        this.$emit('itemAddressChangeEv', link, e.target.value)
    },
    setValue(link){
        switch(link){
            case "name":
            return this.name_edit_address
            case "address1":
            return this.address1_edit_address
            case "address2":
            return this.address2_edit_address
            case "postcode":
            return this.postcode_edit_address
            case "city":
            return this.city_edit_address
            case "country":
            return this.country_edit_address
            default: 
            return "";
        }
    },
    updateAddress()
    {
        this.$emit('updateAddressEv')
    }

},
data(){
    const {form} = formAddress
    return{
        form
    }
}
}
</script>

<style>

</style>
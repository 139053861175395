<template>
  <div class="popup-share-scan login-form"  v-if="displayPopupShareScan" @click="closeModal">
    <div class="content">
        <img src="../../../public/assets/images/cross.svg" @click="displayPopupShareScanToFalse" class="cross" width="40" />
        <input type="text" id="email_register_account" v-model="shareEmail" placeholder="Email de l'utilisateur">
        <center><button @click="sendScan()">Envoyer le scan</button></center>
        <center v-if="errorShare!=''"><br />{{errorShare}}</center>
    </div>
</div>
</template>

<script>
import jQuery from 'jquery';
export default {
    name: "PopupShareScan",
    props: ["displayPopupShareScan","errorShare"],
    methods: {

        closeModal($event)
        {
            
            if(jQuery($event.target).parents('.conent').length)
            {
              
            }
            else
            {
                this.$emit('displayPopupShareScanToFalseEv')
                $event.preventDefault()
            }
            
        },


        displayPopupShareScanToFalse()
        {
            this.$emit('displayPopupShareScanToFalseEv')
        }
    }

}
</script>

<style scoped>
.cross {
    cursor:pointer;
}
</style>
<template>
  <div id="account-details" class="subcategory-account" v-if="displayAccountContent">
     <h4  v-if="language=='fr'">Détails du compte</h4>
     <h4  v-else>Account details</h4>
     <span  v-if="language=='fr'"> <b>Nom :</b> {{capitalizeFirstLetter(account_name)}} {{capitalizeFirstLetter(account_surname)}}</span>
     <span   v-else> <b>Name :</b> {{capitalizeFirstLetter(account_name)}} {{capitalizeFirstLetter(account_surname)}}</span>
     <br />
     <span  v-if="language=='fr'"> <b>Email : </b> {{account_email}}</span>
     <span   v-else> <b>Email : </b> {{account_email}}</span>
     <br /><br />
    <a v-if="language=='fr'" href='#' @click="editAccount()" class='edit-account-details-link'>Modifier</a> 
    <a v-else href='#' @click="editAccount()" class='edit-account-details-link'>Edit</a>        
    
    <br /><br />
</div>
</template>

<script>
import capitalizeFirstLetter from "../../../../../utils/format/capitalizeFirstLetter"

export default {
     name: "AccountDetails",
     props: ["account_name", "account_surname", "account_email", "displayAccountContent","language"],
     methods: {
        editAccount()
        {
            this.$emit('editAccountEv')
        }
     },
     data(){
        return{
            capitalizeFirstLetter
        }
     }

}
</script>

<style>

</style>
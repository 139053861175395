<template>
  <div id="form-create-address" class="subcategory-account" v-if="displayAddAddressForm">
    <div class="login-form">
        <h4 v-if="language=='fr'">Nouvelle adresse</h4>  
        
      <template v-for="item in form_create" :key="item.link">
        <input v-if="language=='fr'" type="text" :id="item.id" v-on:change="dataChange(item.link,$event)" :placeholder="item.placeholder">
        <input v-else type="text" :id="item.id" v-on:change="dataChange(item.link,$event)" :placeholder="item.placeholder_en">
        <br />
      </template>

        
        <!-- <input type="text" id="name_create_address" v-model="address_name" placeholder="Nom"><br />
        <input type="text" id="address1_create_address" v-model="address_address1" placeholder="Adresse 1"><br />
        <input type="text" id="address2_create_address"  v-model="address_address2" placeholder="Adresse 2"><br />
        <input type="text" id="postcode_create_address"  v-model="address_postcode" placeholder="Code postal"><br />
        <input type="text" id="city_create_address" v-model="address_city" placeholder="Ville"><br />
         <input type="text" id="" v-model="address_first_name" placeholder="Nom de famille"><br />
          <input type="text" id="" v-model="address_last_name" placeholder="Prénom"><br />
           <input type="text" id="" v-model="address_phone" placeholder="Téléphone"><br />
        <input type="text" id="country_create_address"  v-model="address_country" placeholder="Pays"><br /> -->

        <input type="hidden" id="default_address_create_address" v-model="address_default_address" placeholder="Nom">
        <br />
        <button v-if="language=='fr'" id="create_address_button" @click="createAddress()">Ajouter une adresse</button>
        <button v-else id="create_address_button" @click="createAddress()">Add address</button>
        <div id="error-message-address-create"></div>
        
    </div>
</div>
</template>

<script>
import formCreateAddress from '../../../../../data/account/form_create_address';

export default {
  name : "AccountCreateAddress",
  props: ["displayAddAddressForm","language"],
  methods: {
    createAddress()
    {
      this.$emit('createAddressEv')
    },
    dataChange(link,e)
    {
      this.$emit('itemAddressCreateChangeEv', link, e.target.value )
    }
  },
  data(){
    const {form_create} = formCreateAddress;
    return{
      form_create
    }
  }

}
</script>

<style>

</style>
<template>
  <div id="content-prescriptions" class="subcategory-account" v-if="displayPrescriptionContent">
    <h4 v-if="language=='fr'">Vos ordonnances</h4>
    <h4 v-else>Your prescriptions</h4>
    <table class='table'>
        <tr v-for="(prescription, index) in userPrescriptions" :key="prescription.id">
            <td>{{prescription.name}}</td>
            <td>
                <a v-if="language=='fr'"  :data-id="prescription.id" :data-index="index" class="address-delete" @click="deletePrescription(prescription.id)">Supprimer</a>
                <a v-else :data-id="prescription.id" :data-index="index" class="address-delete" @click="deletePrescription(prescription.id)">Delete</a>
            </td>
            <td>
                <a  v-if="language=='fr'" class="address-delete" @click="displayPrescriptionDetails(index)">Voir l'ordonnance</a>
                <a  v-else class="address-delete" @click="displayPrescriptionDetails(index)">See prescription</a>
            </td>
            
        </tr>
    </table>
    <span v-if="userPrescriptions.length==0">
        <span v-if="language=='fr'">Vous n'avez pas encore d'ordonnance pour le moment.</span>
        <span v-else>You don't have any prescription for the moment.</span>
        <br /><br /></span>

    <div id="prescription_content" v-if="prescriptionSelected!=null">

        <b>Nom :</b> {{prescriptionSelected.name}}
        <br />
        <div v-if="prescriptionSelected.file!=null && prescriptionSelected.file!=''">
            <span v-if="language=='fr'"><b>Fichier :</b> <a :href="prescriptionSelected.file">Télécharger</a></span>
            <span v-else><b>File :</b> <a :href="prescriptionSelected.file">Download</a></span>
        </div>
        <div v-else>
            <span v-if="language=='fr'">
                <b>Oeil gauche :</b><br /><br />
                Sphère : {{prescriptionSelected.left_sphere}}<br />
                Cylindre : {{prescriptionSelected.left_cylinder}}<br />
                Addition : {{prescriptionSelected.left_addition}}<br />
                Axe : {{prescriptionSelected.left_axis}}<br />
                Ecart pupillaire : {{prescriptionSelected.left_pupillary_distance}}<br />
                Prisme : {{prescriptionSelected.left_prism}}<br />

                <br />

                <b>Oeil droit :</b><br /><br />
                Sphère : {{prescriptionSelected.right_sphere}}<br />
                Cylindre : {{prescriptionSelected.right_cylinder}}<br />
                Addition : {{prescriptionSelected.right_addition}}<br />
                Axe : {{prescriptionSelected.right_axis}}<br />
                Ecart pupillaire : {{prescriptionSelected.right_pupillary_distance}}<br />
                Prisme : {{prescriptionSelected.right_prism}}<br />

                <br />

                Ecart pupillaire total : {{prescriptionSelected.pupillary_distance}}
            </span>
            <span v-else>
                <b>Left eye :</b><br /><br />
                Sphere : {{prescriptionSelected.left_sphere}}<br />
                Cylinder : {{prescriptionSelected.left_cylinder}}<br />
                Addition : {{prescriptionSelected.left_addition}}<br />
                Axis : {{prescriptionSelected.left_axis}}<br />
                Pupillary distance : {{prescriptionSelected.left_pupillary_distance}}<br />
                Prism : {{prescriptionSelected.left_prism}}<br />

                <br />

                <b>Right eye :</b><br /><br />
                Sphere : {{prescriptionSelected.right_sphere}}<br />
                Cylinder : {{prescriptionSelected.right_cylinder}}<br />
                Addition : {{prescriptionSelected.right_addition}}<br />
                Axis : {{prescriptionSelected.right_axis}}<br />
                Pupillary distance : {{prescriptionSelected.right_pupillary_distance}}<br />
                Prism : {{prescriptionSelected.right_prism}}<br />

                <br />

                Total pupillary distance : {{prescriptionSelected.pupillary_distance}}
            </span>
            <br /><br />
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: "AccountPrescriptions",
    props: ["displayPrescriptionContent", "userPrescriptions", "prescriptionSelected","language"],
    methods: {
        deletePrescription(id)
        {
            this.$emit('deletePrescriptionEv',id)
        },
        displayPrescriptionDetails(index)
        {
            this.$emit('displayPrescriptionDetailsEv', index)
        }
    },
    data(){
        return{

        }
    }
}
</script>

<style>

</style>
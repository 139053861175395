export default {
    paramsRegister: [
        {
            id: "name_register",
            type: "text",
            placeholder: "Prénom*",
            placeholder_en:"Name*",
            link:"register_name",
        },
        {
            id: "surname_register",
            type: "text",
            placeholder: "Nom*",
            placeholder_en:"Name*",
            link:"register_surname",
        },
        {
            id: "email_register",
            type: "text",
            placeholder: "Email*",
            placeholder_en: "Email*",
            link:"register_email",
        },
        {
            id: "password_register",
            type: "password",
            placeholder: "Mot de passe*",
            placeholder_en:"Password*",
            link:"register_password",
        },
        {
            id: "password_repeat_register",
            type: "password",
            placeholder: "Répétez mot de passe*",
            placeholder_en:"Repeat password*",
            link:"register_password_repeat",
        },
        
    ]
}
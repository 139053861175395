<template>
  <div id="content-addresses" class="subcategory-account" v-if="displayAddressContent">
    <h4 v-if="language=='fr'">Vos adresses</h4>
    <h4 v-else>Your addresses</h4>
    <table class='table'>
        <tr v-for="(address, index) in addresses" :key="address.id">
            <td>{{address.name}}</td>
            <td>{{address.address1}} {{address.postcode}} {{address.city}}</td>
            <td>
                <a v-if="language=='fr'" :data-id="address.id" :data-index="index" @click="editAddress(address.id,index)" class="address-edit">Modifier</a>
                <a v-else :data-id="address.id" :data-index="index" @click="editAddress(address.id,index)" class="address-edit">Edit</a>
                 |
                <a v-if="language=='fr'" :data-id="address.id" :data-index="index" class="address-delete" @click="deleteAddress(address.id)">Supprimer</a>
                <a v-else :data-id="address.id" :data-index="index" class="address-delete" @click="deleteAddress(address.id)">Delete</a>
            </td>
        </tr>
    </table>
    <a  v-if="language=='fr'" id='add_address' @click.prevent="addAddress()">+ nouvelle adresse</a>
    <a  v-else id='add_address' @click.prevent="addAddress()">+ new address</a>
    <br /><br />
</div>
</template>

<script>
export default {
    name: "AccountAdresses",
    props: ["displayAddressContent", "addresses","language"],
    methods: {
        editAddress(id,index)
        {
            this.$emit('editAddressEv', id,index)
        },
        deleteAddress(id)
        {
            this.$emit('deleteAddressEv', id)
        },
        addAddress()
        {
            this.$emit('addAddressEv')
        }
    },
    data(){
        return{

        }
    }
}
</script>

<style>

</style>
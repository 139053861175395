<template>
  <div id="content-reviews" class="subcategory-account" v-if="displayReviewContent">
    <h4 v-if="language=='fr'" >Vos avis</h4>
    <h4 v-else >Your reviews</h4>
    <table class='table'>
        <tr v-for="(review, index) in userReviews" :key="review.id">
            <td>{{review.title}}</td>
            <td>{{review.date}}</td>
            <td>
               {{review.glass.name}}
               
            </td>
            <td>
                <a v-if="language=='fr'"  :data-id="review.id" :data-index="index" class="address-delete" @click="deleteReview(review.id)">Supprimer</a>
                <a v-else  :data-id="review.id" :data-index="index" class="address-delete" @click="deleteReview(review.id)">Delete</a>
            </td>
        </tr>
    </table>
    <span v-if="userReviews.length==0">
        <span v-if="language=='fr'">Vous n'avez pas encore donné d'avis pour le moment.</span>
        <span v-else>You don't have post any review for the moment.</span>
        <br /><br /></span>

</div>
</template>

<script>
export default {
    name: "AccountReviews",
    props: ["displayReviewContent", "userReviews","language"],
    methods: {
        deleteReview(id)
        {
            this.$emit('deleteReviewEv', id)
        }
    },
    data(){
        return{

        }
    }

}
</script>

<style>

</style>
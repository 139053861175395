<template>
  <ul class="menu-account">
    <template v-for="item in menu" :key="item.text">
        <li :class="item.class + '-details-link'" @click="select(item.link)"><div class="img"><img :src="require('../../../../../public/assets/images/' + item.img)" :width="width" /></div> {{ item.text }}</li>
    </template>
</ul>

</template>

<script>
import menuData from "../../../../data/account/menu_data"
export default {
    name: "AccountMenu",
    props: [],
    methods: {
        select(link)
        {
            this.$emit('selectEv', link)
        }
    },
    data(){
        const {menu} = menuData;
        return{
            menu,
            width: 20,
        }
    }

}
</script>

<style scoped>
.menu-account li {
    position: relative;
}
.menu-account li::after{
    content:"+";
    position: absolute;
    right:20px;
    top:15px;
   

}
</style>
<template>
  <div class="popup_scan" @click="closeModal">
    <div class="content">
       <img class="cross" width="46" src="../../../public/assets/images/cross.svg" @click="hidePopup()" />
      <div id="container"></div> 
    </div>
  </div>
</template>


<script>
import jQuery from 'jquery';
import {APIRequest} from "@simb2s/senseye-sdk";
import emitter from 'tiny-emitter/instance'
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';

var camera, controls, scene, renderer, center;

export default {
  components: {  },
  name: 'PopupScan',
  props:['scan',"language"],
  data() {
    return {
      scanDetail:null,
      center:null,
      camera:null,
      controls:null,
      scene:null, 
      renderer:null,
      container:null,
      geometry:null,
      ptcloud_array:[]
    }
  },
  async mounted(){

    await this.getScanDetails()


    var pointCloud = this.scanDetail.coordinates;

    this.ptcloud_array = pointCloud.split(/\r?\n/);
 
    this.container = document.getElementById("container");
    this.init()
  },
  beforeUnmount() {
        window.removeEventListener('resize', this.onWindowResize);
  },
  methods:{
    closeModal($event)
        {
            
            if(jQuery($event.target).parents('.conent').length)
            {
              
            }
            else
            {
              emitter.emit('hide-popup-scan')
                $event.preventDefault()
            }
            
        },
    hidePopup() {
      emitter.emit('hide-popup-scan')
    },
    async getScanDetails() {
      this.scanDetail=await APIRequest.detail('scans',this.scan)
   
    },
    init() {
      renderer = new THREE.WebGLRenderer( { antialias: true } );
		
      renderer.setPixelRatio( window.devicePixelRatio );
      renderer.setSize( container.offsetWidth, container.offsetHeight );
      container.appendChild( renderer.domElement );
      scene = new THREE.Scene();
      scene.background = new THREE.Color( 0xffffff );
      camera = new THREE.PerspectiveCamera( 15, container.offsetWidth / container.offsetHeight, 0.01, 40 );
      camera.position.x = 1.5; // x and z determines face direction
      camera.position.z = 0; // - 2
      camera.position.y = 0; // y determines angle from above
      scene.add( camera );

      controls = new OrbitControls( camera, renderer.domElement );

      var geometry = new THREE.BufferGeometry();
      var points_array=[]
      var colors_array=[]
      
      for(let i=0;i<this.ptcloud_array.length;i++) {
      
        var points_ex=this.ptcloud_array[i].split(',')
        points_array.push(points_ex[0])
        points_array.push(points_ex[1])
        points_array.push(points_ex[2])
        
        colors_array.push(points_ex[3],points_ex[4],points_ex[5])
        
      }
      
      
      var points_vertices = new Float32Array( points_array);
      
      var colors = new Uint8Array( colors_array);
    
      
      geometry.setAttribute( 'position', new THREE.BufferAttribute( points_vertices, 3 ) );
      geometry.setAttribute( 'color', new THREE.BufferAttribute( colors, 3 ) );
      
      geometry.attributes.color.normalized = true;
        
      
      var material = new THREE.PointsMaterial({vertexColors: true, size: 0.0045 })
      
      var mesh = new THREE.Points(geometry, material );

      mesh.name = "headp.ply";
      scene.add( mesh );

      if(mesh.geometry.boundingSphere!=null) {
         center = mesh.geometry.boundingSphere.center;
    
        camera.lookAt(center);
        center.x -= 0.03;
        center.y += 0;
        
     
      }
        
      mesh.rotation.y+=1.6
      
      this.animate()

      window.addEventListener( 'resize', this.onWindowResize, false );
    },
    onWindowResize() {
      camera.aspect = container.offsetWidth / container.offsetHeight;
      camera.updateProjectionMatrix();
     renderer.setSize( container.offsetWidth, container.offsetHeight );
      controls.handleResize();
    },
    animate() {
      requestAnimationFrame( this.animate );
      renderer.render( scene, camera );
    }
    
  },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  #container {
    width:100%;
    height:100%;
  }
  .content .cross {
    position:absolute;
    right:-10px;
    top:-20px;
    cursor: pointer;
  }
  .content {
    background:#fff;
    position:absolute;
    left:50%;
    top:50%;
    transform:translate(-50%,-50%);
    width:80%;
    height:80%;
  }
  .popup_scan {
    position:fixed;
    top:0;
    left:0;
    width:100%;
    height:100%;
    z-index:999999;
    background:rgba(0,0,0,0.7);
  }
</style>

<template>
  <div id="content-cards" v-if="displayCards" class="subcategory-account">
    
    <h4 v-if="language=='fr'">Vos cartes</h4>
    <h4 v-else>Your cards</h4>
    <table class='table' v-if="cards.length>0">
        <tr v-for="(card, index) in cards" :key='card.id'>
            <td>**** **** **** {{card.last4}} ({{card.brand}})</td>
            <td>
                <a v-if="language=='fr'" href="#" :data-index="index" @click="removeCard(card.id)" class="remove-card">Supprimer</a>
                <a v-else href="#" :data-index="index" @click="removeCard(card.id)" class="remove-card">Remove</a>
            </td>
        </tr>
    </table>
    <div v-else>
        <span v-if="language=='fr'">Vous n'avez aucun moyen de paiement pour le moment.</span>
        <span v-else>You don't have any payment method for the moment.</span>
    </div>
    <a  v-if="language=='fr'" @click.prevent="addCardForm(e)" id='add_card'>+ nouvelle carte</a>
    <a  v-else @click.prevent="addCardForm(e)" id='add_card'>+ new card</a>
    <br /><br />
</div>
</template>

<script>
export default {
    name: 'AccountCards',
    props: ["displayCards","cards","language"],
    methods: {
        removeCard(id)
        {
            this.$emit('removeCardEv',id)
        },
        addCardForm()
        {
            
            this.$emit('addCardFormEv')
        }
    }

}
</script>

<style>

</style>
import moment from 'moment';

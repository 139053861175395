<template>
  <div id="form-edit-scan" class="subcategory-account" v-if="displayEditScanForm">
    <h4 v-if="language=='fr'">Modifier un scan</h4>  
    <div class="login-form">
        <span v-if="language=='fr'">Nom</span><span v-else>Name</span>  :<br />
        <input type="hidden" id="id_edit_scan" :value="id_edit_scan" v-on:change="changeIDScan($event)" placeholder="Nom">
        <input v-if="language=='fr'"  type="text" id="name_edit_scan" :value="name_edit_scan" v-on:change="changeNameScan($event)" placeholder="Nom">
        <input v-else  type="text" id="name_edit_scan" :value="name_edit_scan" v-on:change="changeNameScan($event)" placeholder="Name">
        <br />
    
        <button v-if="language=='fr'" id="edit_address_button" @click="updateScan()" >Modifier mon scan</button>
        <button v-else id="edit_address_button" @click="updateScan()" >Update scan</button>
        
        <div id="error-message-scan-update"></div>
            
    </div>
</div>
</template>

<script>
export default {
    name: "AccountEditScan",
    props: ["displayEditScanForm", "id_edit_scan", "name_edit_scan","language"],
    methods: {
        updateScan()
        {
            this.$emit('updateScanEv')
        },
        changeIDScan(e)
        {
            this.$emit('changeIDScanEv', e.target.value)
        },
        changeNameScan(e)
        {
            this.$emit('changeNameScanEv', e.target.value)
        },
    }

}
</script>

<style>

</style>
export default {
    form_create : [
        {
            txt:"Nom",
            link:"address_name",
            placeholder:"Nom",
            placeholder_en:"Name",
            id: "name_create_address"
        },
        {
            txt:"Adresse 1",
            link:"address_address1",
            
            placeholder:"Adresse 1",
            placeholder_en:"Address 1",
            id: "address1_create_address"
        },
        {
            txt:"Adresse 2",
            link:"address_address2",
            placeholder:"Adresse 2",
            placeholder_en:"Address 2",
            id: "address2_create_address"
        },
        {
            txt:"Code postal",
            link:"address_postcode",
            placeholder:"Code postal",
            placeholder_en:"Postcode",
            id: "postcode_create_address"
        },
        {
            txt:"Ville",
            link:"address_city",
            placeholder:"Ville",
            placeholder_en:"City",
            id: "city_create_address"
        },
        {
            txt:"Nom de famille",
            link:"address_first_name",
            placeholder:"Nom de famille",
            placeholder_en:"Name",
            id: ""
        },
        {
            txt:"Prénom",
            link:"address_last_name",
            placeholder:"Prénom",
            placeholder_en:"Surname",
            id: ""
        },
        {
            txt:"Téléphone",
            link:"address_phone",
            placeholder:"Téléphone",
            placeholder_en:"Phone",
            id: ""
        },
        {
            txt:"Pays",
            link:"address_country",
            placeholder:"Pays",
            placeholder_en:"Country",
            id: "country_create_address"
        },
    ]
}
<template>
   <div id="content-scans" class="subcategory-account" v-if="displayScanContent">
    <h4 v-if="language=='fr'">Vos scans</h4>
    <h4 v-else>Your scans</h4>
    <div class="loader" v-if="loadingScans==true"><img src="../../../../../../public/assets/images/2021/05/loader.gif"></div>
	
    <template v-else>
    <table class='table'>
        <tr v-for="(scan, index) in userScans" :key="scan.id">
            <td>{{scan.name}}</td>
            <td>{{scan.date}}</td>
            <td>
                <img v-if="scan.photo!=null && scan.photo!=''" :src="'data:image/png;base64,'+scan.photo" width="100" />
             
            </td>
            <td>
                <span v-if="scan.default_scan==true">
                    <span v-if="language=='fr'">Scan par défaut</span>
                    <span v-else>Default scan</span>
                </span>
                <span v-else>
                    <a v-if="language=='fr'" class="address-delete" @click="setDefaultScan(index)">Définir par défaut</a>
                    <a v-else class="address-delete" @click="setDefaultScan(index)">Set as default</a>
                </span>
               
            </td>
            <td>
                
                <center>
                  <img  @click="shareScan(scan.id)" src="../../../../../../public/assets/images/share_icon.svg" width="15" />
                </center>
               
            </td>
            <td>
                
                <center>
                    <img @click="displayPopupScan(scan.id)" src="../../../../../../public/assets/images/zoom.svg" width="20" />
                </center>
               
            </td>
            <td>
           
                <a  v-if="language=='fr'" :data-id="scan.id" :data-index="index" @click="editScan(scan.id,index)" class="address-edit">Modifier</a>
                <a  v-else :data-id="scan.id" :data-index="index" @click="editScan(scan.id,index)" class="address-edit">Edit</a>
                 |
                <a  v-if="language=='fr'" :data-id="scan.id" :data-index="index" class="address-delete" @click="deleteScan(scan.id)">Supprimer</a>
                <a  v-else :data-id="scan.id" :data-index="index" class="address-delete" @click="deleteScan(scan.id)">Delete</a>
            </td>
        </tr>
    </table>
    <span v-if="userScans.length==0">
        <span v-if="language=='fr'">Vous n'avez pas encore effectué de scan pour le moment.</span>
        <span v-else>You don't have any scan for the moment.</span>
        <br /><br /></span>

    </template>

</div>
</template>

<script>
export default {
    name: "AccountScans",
    props: ["displayScanContent", "userScans","language","loadingScans"],
    methods: {
        setDefaultScan(index)
        {
            this.$emit('setDefaultScanEv', index)
        },
        shareScan(id)
        {
            this.$emit('shareScanEv', id)
        },
        displayPopupScan(id)
        {
            this.$emit('displayPopupScanEv', id)
        },
        editScan(id, index)
        {
            this.$emit('editScanEv', id, index)
        },
        deleteScan(id)
        {
            this.$emit('deleteScanEv', id)
        },
    }

}
</script>

<style scoped>
 .loader
 {
    text-align: center;
    width:100%;
 }
</style>